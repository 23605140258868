import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/private">
          <SwaggerUI url={window.location.origin + "/swagger-private.json"} />
          </Route>
          <Route path="/">
            <SwaggerUI url={window.location.origin + "/swagger.json"} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App
